<template>
    <div class="index">
        <!-- 咨询师详情 -->
        <div class="detail">
            <div class="top">
                <div class="left">
                    <img :src="dataList.portrait" alt="" v-if="dataList.portrait">
                    <span v-else>未设置</span>
                    <!-- <img src="../../assets/home/img.png" v-else alt=""> -->
                </div>
                <div class="right">
                    <p class="title">{{ dataList.nickname }}</p>
                    <p class="title">{{ dataList.title }}</p>
                </div>
            </div>
            <div class="bottom" @click="openDetail(dataList.memo)">
                <span>{{ dataList.memo }}</span>
            </div>
        </div>
        <p class="text">
            预约挂号
        </p>
        <div class="container">
            <div class="item" v-for="item in dataList.consultDetailDateVOS" :key="item.id">
                <div class="left">
                    <div class="time">
                        <img src="../../assets/home/time.png" alt="">
                        <span>{{ item.date }} {{ item.week }} {{ item.type }}</span>
                    </div>
                    <div class="money" v-if="settingInfo.reserveIsPayment === true">
                        ￥{{ item.price }}
                    </div>
                </div>
                <template v-if="item.isReserve === true">
                    <div class="right">
                        <div class="btn hasRecord">已预约</div>
                    </div>
                </template>
                <template v-else>
                    <div class="right" v-if="item.number === true">
                        <div class="btn hasTime" @click="appointmentSuccess(item)">预约</div>
                    </div>
                    <div class="right" v-else>
                        <div class="btn noTime">约满</div>
                    </div>
                </template>

            </div>

        </div>

    </div>
</template>

<script>
import { getConsultRecordList } from '@/api/record'
import { getSetting } from '@/api/setting'
export default {
    data() {
        return {
            dataList: [], // 数据列表
            settingInfo: {},

        }
    },
    methods: {
        //咨询师详情弹框
        openDetail(val) {
            this.$alert(val, '咨询师简介', {
                confirmButtonText: '确定',
                callback: action => {
                    console.log(action)
                    // this.$message({
                    //   type: 'info',
                    //   message: `action: ${ action }`
                    // });
                }
            });
        },
        // 点击预约
        async appointmentSuccess(val) {
            // 存储此订单需要的信息
            let consultInfo = {
                consultId: JSON.parse(sessionStorage.getItem('consultInfo')).consultId,
                userId: JSON.parse(localStorage.getItem('userInfo')).id,
                date: val.date,
                consultDateId: val.consultDateId

            }
            sessionStorage.setItem('consultInfo', JSON.stringify(consultInfo));
            let token = 'Lyn' + localStorage.getItem('token')
            let ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger" && this.settingInfo.wechatLogin === 0 && this.settingInfo.reserveIsPayment === true) { // 内置浏览器
                // let info = `${JSON.parse(sessionStorage.getItem('consultInfo')).consultId}/${JSON.parse(localStorage.getItem('userInfo')).id}/${JSON.parse(localStorage.getItem('userInfo')).id}/${val.date + val.consultDateId}/`;
                this.$router.push({
                    path: '/home/appointmentDetails',
                    query: {
                        a: JSON.parse(sessionStorage.getItem('consultInfo')).consultId,
                        b: JSON.parse(localStorage.getItem('userInfo')).id,
                        c: val.date,
                        d: val.consultDateId,
                        code: token
                    }
                })
            } else {
                this.$router.push({
                    path: '/home/appointmentDetails',
                })
            }

        },

        // 获取咨询师列表及咨询师详情
        async getDetail(val) {
            const res = await getConsultRecordList(val);
            console.log(res)
            if (res.code === 200) {
                this.dataList = res.data
            }
            // 把星期转换为汉字
            this.dataList.consultDetailDateVOS.forEach((item, index) => {
                item.id = index + 1
                if (item.week === 1) {
                    item.week = "周一"
                } else if (item.week === 2) {
                    item.week = "周二"
                } else if (item.week === 3) {
                    item.week = "周三"
                } else if (item.week === 4) {
                    item.week = "周四"
                } else if (item.week === 5) {
                    item.week = "周五"
                } else if (item.week === 6) {
                    item.week = "周六"
                } else if (item.week === 7) {
                    item.week = "周日"
                }
                if (item.type === 1) {
                    item.type = '整天'
                } else if (item.type === 2) {
                    item.type = '上午'
                } else if (item.type === 3) {
                    item.type = '下午'
                }
            })
        },
        // 获取配置信息
        async getLogs() {
            const res = await getSetting();
            console.log(res);
            if (res.code === 200) {
                this.settingInfo = res.data;
            }
        },
    },
    created() {
        // console.log(sessionStorage.getItem('consultId'))
        this.getLogs();

        this.getDetail(JSON.parse(sessionStorage.getItem('consultInfo')).consultId);

    },
}
</script>

<style lang="scss" scoped>
.index {
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;

    p {
        margin: 0;
    }



    .detail {
        width: 100%;
        height: 250px;
        border-radius: 19px;
        border: 1px solid #E7E7E7;
        box-sizing: border-box;
        padding: 20px;

        .top {
            display: flex;

            box-sizing: border-box;

            .left {
                img {
                    height: 130px;
                    width: 130px;
                    border-radius: 15px;
                }
            }

            .right {
                margin-left: 15px;

                p {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: #303030;
                    line-height: 20px;
                    margin-top: 25px;

                    &:nth-child(2) {
                        font-weight: 400;
                    }
                }
            }
        }

        .bottom {
            width: 100%;

            span {
                // height: 100%;
                // width: 100%;
                font-size: 14px;
                font-weight: 400;
                color: #7D7D7D;
                display: -webkit-box;
                /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
                -webkit-line-clamp: 4;
                /*设置多少行*/
                -webkit-box-orient: vertical;
                /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
                overflow: hidden;
                /*文本会被修剪，并且剩余的部分不可见*/
                text-overflow: ellipsis;
                // line-height: 23px;
            }
        }
    }

    .text {
        font-size: 16px;
        font-weight: 600;
        color: #303030;
        margin-top: 30px;
    }

    .container {

        .item {
            width: 100%;
            height: 100px;
            border-radius: 19px;
            border: 1px solid #E7E7E7;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px;
            margin-top: 15px;

            .left {
                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 3px;
                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: #303030;
                }

                .money {
                    font-size: 22px;
                    color: var(--custom-color);
                    font-weight: 600;
                }
            }

            .right {
                .btn {
                    width: 90px;
                    height: 35px;
                    line-height: 35px;
                    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
                    border-radius: 6px;
                    color: white;
                    font-size: 14px;
                    // font-weight: 600;
                    text-align: center;

                }

                .noTime {
                    background: #B2B2B2;
                }

                .hasTime {
                    background: var(--custom-color);
                    cursor: pointer;
                }

                .hasRecord {
                    background: #F08457;
                }
            }
        }
    }
}

@media screen and (min-width: 751PX) {
    .index {
        padding: 20px 125px;


    }
}

@media screen and (max-width: 750PX) {
    .index {
        padding: 20px;


    }
}
</style>